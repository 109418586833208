import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { isBlank } from "../../../utils/isBlank"
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share"

const defaultMargin = "mt-6 md:mx-24"

async function fetchImage(id) {
  const { SiteClient } = require("datocms-client")
  const client = new SiteClient("7617f586a3abb029f54ca4026bcae5")

  const image = await client.uploads.find(id)
  return image.url
}

export default function Content({ candidate, setCandidate }) {
  const [img, setImg] = useState(
    <img
      className="block z-0"
      alt={candidate.name + " " + candidate.surname}
      src="./../../loading-animation-2.gif"
    />
  )

  useEffect(() => {
    async function fetchData() {
      const url = await fetchImage(candidate.picture.uploadId)
      setImg(
        <img
          className="block z-0  object-cover object-top"
          alt={candidate.name + " " + candidate.surname}
          src={url}
        />
      )
    }

    if (!candidate.picture.gatsbyImageData) {
      fetchData()
    }
  }, [candidate.name, candidate.picture, candidate.surname])

  return (
    <div className="bg-secondary xl:py-12    w-full  md:w-4/5 xl:max-w-7xl m-auto  flex flex-col overflow-hidden xl:h-[80vh] h-full overflow-y-scroll z+50">
      <Gallery onBack={() => setCandidate()} />
      <div className="flex xl:flex-row flex-col m-auto  ">
        <Picture
          name={candidate.name}
          surname={candidate.surname}
          picture={candidate.picture.gatsbyImageData ? candidate.picture : img}
          candidateNumber={candidate.candidatenumber}
        />
        <div className="xl:w-[70%] md:-mr-12">
          <Name
            name={candidate.name + " " + candidate.surname}
            ammattiTitteli={candidate.ammattiTitteli}
            koulutus={candidate.koulutus}
          />
          <LocationAge
            area={candidate.electionarea}
            municipality={candidate.municipality}
            age={candidate.age}
          />

          <PersonalDescription description={candidate.personalDescription} />
          <CampaignDescription description={candidate.campaignDescription} />
          <Social
            email={candidate.email}
            facebook={candidate.facebook}
            instagram={candidate.instagram}
            blog={candidate.blog}
          />
        </div>
      </div>
      <Share />
      <Gallery onBack={() => setCandidate()} />
    </div>
  )
}

function Share() {
  const [url, setUrl] = useState("")

  useEffect(() => {
    setUrl(window.location.href)
  })

  return (
    <div>
      <hr className="border-solid border-1 border-primary my-2"></hr>
      <div className="flex w-full justify-around m-auto mt-8 ">
        <div className="text-4xl mt-2 text-primary">Jaa:</div>
        <EmailShareButton url={url}>
          <EmailIcon round={true} />
        </EmailShareButton>
        <FacebookShareButton url={url}>
          <FacebookIcon round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={url}>
          <TwitterIcon round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={url}>
          <WhatsappIcon round={true} />
        </WhatsappShareButton>
      </div>
    </div>
  )
}

function Gallery({ onBack }) {
  const [isWindowBlank, setWindowBlank] = useState(false)
  const [areaSel, setAreaSel] = useState(null)
  var defaultParameter = ""
  if (isWindowBlank) defaultParameter = "?blank=true"
  if (areaSel) {
    if (defaultParameter === "") defaultParameter = "?vaalipiiri=" + areaSel
    else defaultParameter += "&vaalipiiri=" + areaSel
  }
  useEffect(() => {
    setWindowBlank(isBlank(window))
    if (window.area) setAreaSel(window.area)
  })
  return (
    <div
      className="ml-6 mt-6 text-primary text-xl cursor-pointer"
      onClick={() => {
        onBack()
        const url = new URL(window.location)
        url.searchParams.delete("candidate")
        window.history.pushState({}, "", url)
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 inline mr-1 "
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
          clip-rule="evenodd"
        />
      </svg>
      Takaisin ehdokaslistaukseen{" "}
    </div>
  )
}

function Name({ name, koulutus, ammattiTitteli }) {
  name = name
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

  return (
    <div>
      <h1
        className={
          defaultMargin + " text-center font-bold p-2 text-2xl md:text-4xl "
        }
      >
        {name}
      </h1>
      <p className="text-center w-[60%] m-auto">
        {[koulutus, ammattiTitteli].filter(t => t !== "").join(", ")}
      </p>
    </div>
  )
}

function LocationAge({ area, municipality, age }) {
  return (
    <p
      className={
        defaultMargin +
        " text-2xl bg-primary p-3 flex flex-row lg:justify-around justify-center  text-white md:mr-12"
      }
    >
      <p className="m-2 lg:m-0 text-xl">Kunta: {municipality}</p>
      <p className="m-2 lg:m-0 text-xl">Vaalipiiri: {area}</p>
      <p className="m-2 lg:m-0 text-xl">Ikä: {age + " vuotta"}</p>
    </p>
  )
}

function Picture({ name, surname, picture, candidateNumber }) {
  var image = null

  if (picture.gatsbyImageData) {
    image = (
      <GatsbyImage alt={name + " " + surname} image={picture.gatsbyImageData} />
    )
  } else {
    image = picture
  }

  return (
    <div className={" w-64 m-auto  xl:my-auto xl:ml-8 xl:-mr-12  relative  "}>
      {image}{" "}
      {candidateNumber && (
        <p className="absolute bottom-3 left-3 z-20 bg-primary text-3xl p-2 rounded-2xl text-white">
          {candidateNumber}
        </p>
      )}
    </div>
  )
}

function PersonalDescription({ description }) {
  return (
    <div>
      {/* <h2 className={defaultMargin}>Esittely</h2> */}
      <pre
        className={
          defaultMargin +
          " xl:text-base text-base leading-loose font-serif block whitespace-pre-wrap ml-2 md:mr-14"
        }
      >
        {description}
      </pre>
    </div>
  )
}

function CampaignDescription({ description }) {
  return (
    <div>
      {/* <h2 className={defaultMargin}>Kampanjateemat</h2> */}
      <pre
        className={
          defaultMargin +
          " xl:text-base text-base leading-loose font-serif block whitespace-pre-wrap ml-2 md:mr-14"
        }
      >
        {description}
      </pre>
    </div>
  )
}

function Social({ email, facebook, instagram, blog }) {
  const ExternalLink = ({ icon, link }) => {
    if (!link.match(/https:\/\//g)) link = "https://" + link

    return (
      <div>
        <a
          className={
            defaultMargin +
            " block text-xl  sm:text-3xl underline text-blue-400 italic w-6  "
          }
          href={link}
        >
          {icon}
        </a>
      </div>
    )
  }

  return (
    <div className="">
      {email || facebook || instagram || blog ? (
        <h3 className="mt-6 ml-2 md:mx-24 block text-2xl sm:text-2xl text-primary">
          Yhteystietoni
        </h3>
      ) : null}
      <div className="flex justify-start space-x-2">
        {email ? (
          <a
            href={"mailto:" + email}
            className="mt-6 ml-6 mr-6 md:ml-24 md:mr-12 text-xl sm:text-3xl underline text-blue-400 italic flex "
          >
            <div className="fill-primary w-6  ">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
              </svg>
            </div>
          </a>
        ) : null}
        {facebook ? (
          <ExternalLink
            icon={
              <div className="fill-primary">
                <svg viewBox="0 0 24 24">
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            }
            link={facebook}
          />
        ) : null}
        {instagram ? (
          <ExternalLink
            icon={
              <div className="fill-primary">
                <svg viewBox="0 0 24 24">
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            }
            link={instagram}
          />
        ) : null}
        {blog ? (
          <ExternalLink
            icon={
              <div className="fill-primary">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 21 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z" />
                </svg>
              </div>
            }
            link={blog}
          />
        ) : null}
      </div>
    </div>
  )
}
